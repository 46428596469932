import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';

const App = () => {
  const [gradientIndex, setGradientIndex] = useState(0);
  const gradients = [
    'linear-gradient(45deg, #FFA500, #FF1493)',
    'linear-gradient(45deg, #FF1493, #32CD32)',
    'linear-gradient(45deg, #32CD32, #FFA500)',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setGradientIndex((prevIndex) => (prevIndex + 1) % gradients.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <p className='Landing-Heading'>
          Smit Shah
        </p>
        <p className='Landing-Sub-Heading'>
          Something is cooking up...👨🏻‍🍳
        </p>
      </header>
    </div>
  );
}

export default App;
